import {makeAutoObservable} from "mobx";

export default class DeviceStore {
    constructor() {
        this._types = []
        this._banneritems = []
        this._devices = []
        this._selectedType = {}
        this._selectedOrder = 'id,ASC'
        this._selectedGame = {}
        this._cartCount = 2
        this._selectedBanneritems = {}
        this._page = 1
        this._totalCount = 0
        this._limit = 12
        makeAutoObservable(this)
    }

    setTypes(types) {
        this._types = types
    }
    setBanneritems(banneritems) {
        this._banneritems = banneritems
    }
    setDevices(devices) {
        this._devices = devices
    }
    setSelectedType(type) {
        this.setPage(1)
        this._selectedType = type
    }
    setSelectedOrder(order) {
        this.setPage(1)
        this._selectedOrder = order
    }
    setSelectedGame(device) {
        this._selectedGame = device
    }
    setCartCount(count) {
        this._cartCount = count
    }
    setPage(page) {
        this._page = page
    }
    setTotalCount(count) {
        this._totalCount = count
    }

    get types() {
        return this._types
    }
    get banneritems() {
        return this._banneritems
    }
    get devices() {
        return this._devices
    }
    get selectedType() {
        return this._selectedType
    }
    get selectedOrder() {
        return this._selectedOrder
    }
    get selectedGame() {
        return this._selectedGame
    }
    get cartCount() {
        return this._cartCount
    }
    get selectedBanneritems() {
        return this._selectedBanneritems
    }
    get totalCount() {
        return this._totalCount
    }
    get page() {
        return this._page
    }
    get limit() {
        return this._limit
    }
}