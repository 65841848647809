import {$authHost, $host} from "./index";


export const createSendOrder = async (order) => {
    try {
        const {data} = await $host.post('api/order', order)
        if (data.status == 201)
        return (data)
    } catch (e) {
        console.log(e)
        if (e.response.status == 400) {
            return ({status: 400})
        }
        return (e.response.data)
    }
    
}

