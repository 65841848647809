import React, {useContext, useEffect, useState} from 'react';
import {Route, BrowserRouter as Router} from "react-router-dom";
import AppRouter from "./components/AppRouter";
import NavBar from "./components/NavBar";

import {observer} from "mobx-react-lite";
import {Context} from "./index";
import {check} from "./http/userAPI";
import {Button, Spinner} from "react-bootstrap";
import Footer from './components/Footer';
import Loader from './components/Loader';

const App = observer(() => {
    const {user, cartStore} = useContext(Context)
    const [loading, setLoading] = useState(true)

    let cart = JSON.parse(localStorage.getItem("bag"));
    if (cart) cartStore.setCartCount(cart.length);

    useEffect(() => {
        check().then(data => {
            if (data) {
            user.setUser(true)
            user.setIsAuth(true)
        }}).finally(() => setLoading(false))
    }, [])

    

    

    if (loading) {
        return <Loader/>
    }
        return (
            <Router>
                <NavBar />
                <AppRouter />
                <Footer />
            </Router>
        );
});

export default App;