import React, {useContext, useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {Button, Dropdown, Form, Row, Col} from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import {Context} from "../../index";
import {createBannerItem, fetchDevices, fetchTypes} from "../../http/deviceAPI";
import {observer} from "mobx-react-lite";

const CreateBannerItem = observer(({show, onHide}) => {
    const {device} = useContext(Context)
    const [file, setFile] = useState(null)
    const [text, setText] = useState('')
    

    useEffect(() => {
        fetchTypes().then(data => device.setTypes(data))
        fetchDevices(null, 0, 10000).then(data => {
            device.setDevices(data.rows)
        })
    }, [])



    const selectFile = e => {
        setFile(e.target.files[0])
    }

    const addItem = () => {
        const formData = new FormData()
        formData.append('img', file)
        formData.append('text', text)
        formData.append('gameId', device.selectedGame.id)
        createBannerItem(formData)
        .then(data => {if (data) {alert(data.message);
            window.location.reload()}}) 
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Добавить страницу банера
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>

                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label style = {{marginBottom: '0px', paddingBottomBottom: '0px'}}>Текст для страницы</Form.Label>
                    <Form.Control
                        value={text}
                        onChange={e => setText(e.target.value)}
                        className="mt-3"
                        placeholder="Введите текст"
                        style = {{marginTop: '0px'}}
                    /></Form.Group>
                    <hr/>

                <Dropdown className="mt-2 mb-2">
                        <Dropdown.Toggle>{device.selectedGame.name || "Выберите игру"}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {device.devices.map(game =>
                                <Dropdown.Item
                                    onClick={() => 
                                        device.setSelectedGame(game) 
                                    }
                                    key={game.id}
                                    
                                >
                                    {game.name}
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                    <hr/>


                    <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Выберите изображение</Form.Label>
                    <Form.Control
                        type="file"
                        onChange={selectFile}
                    /></Form.Group>
                    <hr/>
                   

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-success" onClick={addItem}>Добавить</Button>
                <Button variant="outline-danger" onClick={onHide}>Закрыть</Button>  
            </Modal.Footer>
        </Modal>
    );
});

export default CreateBannerItem;