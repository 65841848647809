import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import ListGroup from "react-bootstrap/ListGroup";

import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TypeBar from "../components/TypeBar";
import DeviceList from "../components/DeviceList";
import Loader from './Loader';


import { fetchStages } from "../http/stagesAPI";
import Pages from "../components/Pages";
import SortBar from '../components/SortBar';
import '../stiles/Stages.css'

const GameplayRounds = observer(() => {
    const {stage} = useContext(Context)
    const [loading, setLoading] = useState(true)
    let loaded = false;

    useEffect(() => {
        fetchStages().then(data => {
            if (data && data.length > 0) {
                stage.setStages(data)
                loaded = true;
                }
            })
        .catch((e) => {
            loaded = false;
             })
        .finally(() => {if (loaded) setLoading(false)})
        
    }, [])

    if (loading) {
        return (<Loader/>); // отображение загрузки
      }
    return (
        <section className='stagesContainer'>
            {stage.stages.map(stage =>
            <article className='stagesSection'>
            <h2>{stage.name.toUpperCase()}</h2>
            {stage.text}
            </article>
            )}
        </section>
            
    );
});

export default GameplayRounds;