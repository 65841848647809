import React, {useContext, useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {Form, Button,  Dropdown} from "react-bootstrap";
import {deleteBannerItem, fetchBannerItem} from "../../http/deviceAPI";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

const DeleteBannerItem = observer(({show, onHide}) => {
    const [value, setValue] = useState('')
    const {device} = useContext(Context)

    const delGame = () => {
        deleteBannerItem(device.selectedGame.id)
        .then(data => {if (data) {alert(data.message);
            window.location.reload()}}) 
    };


    useEffect(() => {
        fetchBannerItem().then(data => device.setBanneritems(data))
    }, [])

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Удалить страницу банера
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Dropdown className="mt-2 mb-2">
                        <Dropdown.Toggle>{device.selectedGame.text || "Выберите страницу"}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {device.banneritems.map(item =>
                                <Dropdown.Item
                                    onClick={() => 
                                        device.setSelectedGame(item) 
                                    }
                                    key={item.id}
                                    
                                >
                                    {item.text}
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-danger" onClick={onHide}>Закрыть</Button>
                <Button variant="outline-success" onClick={delGame}>Удалить</Button>
            </Modal.Footer>
        </Modal>
    );
});

export default DeleteBannerItem;