import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {Row} from "react-bootstrap";
import PostItem from "./PostItem";

const PostsList = observer(() => {
    const {post} = useContext(Context)

    return (
        <>
            {post.posts.map(post =>
                <PostItem key={post.id} post={post}/>
            )}
        </>
    );
});

export default PostsList;