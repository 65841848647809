import React, {useContext, useEffect, useState} from 'react';
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TypeBar from "../components/TypeBar";
import SubscribeBar from "../components/SubscribeBar";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import { fetchDevices, fetchTypes, fetchBannerItem} from "../http/deviceAPI";
import Pages from "../components/Pages";
import '../stiles/Home.css'

// import Carousel from 'react-bootstrap/Carousel';
import { ConfigProvider, Space, Carousel } from 'antd';

import Image from 'react-bootstrap/Image';
import {useNavigate, useLocation} from "react-router-dom";
import {Button} from "react-bootstrap";
import {SHOP_ROUTE, DEVICE_ROUTE} from "../utils/consts";
import Footer from '../components/Footer';
import DeviceList from '../components/DeviceList';
import HomeAbout from '../components/HomeAbout';
import NavBarLight from "../components/NavBarLight";
import Loader from '../components/Loader';

const Home = observer(() => {
    const {device} = useContext(Context)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    let loaded = false;


    useEffect(() => {
        fetchBannerItem().then(data => {
            if (data && data.length > 0) {
                device.setBanneritems(data)
                loaded = true;
            }
        })
    .catch((e) => {
        loaded = false;
         })
    .finally(() => {if (loaded) setLoading(false)})
        
        fetchDevices(null, 1, 5).then(data => {
            if (data) {
            device.setDevices(data.rows)
            loaded = true;
                }
            })
        .catch((e) => {
            loaded = false;
            })
        .finally(() => {if (loaded) setLoading(false)})

}, [])
      
    const contentStyle = {
        height: '160px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
      };

      if (loading) {
        return (<Loader/>); // отображение загрузки
      }
    return (
        <>
<section className='carousel'>
    <div className='carouselHeader '><NavBarLight/></div>

<div>
<ConfigProvider
                theme={{
                    components: {
                    Carousel: {
                        dotWidth: 50,
                        dotHeight: 15,
                        algorithm: true, // Enable algorithm
                    }
                    },
                }}
                >

<Carousel autoplay className="Carousel" autoplaySpeed={5000}>
    {device.banneritems.map(item =>
    <div style={{backgroundColor: 'black'}}>
    <h3 className="HomebannerImgContainer" 
    style={{backgroundImage: `url(${process.env.REACT_APP_API_URL + item.img})`}}
    >
        
        <h1 className="HomebannerImgContainerText">{item.text}
        <br/>
        <Button className="HomebannerImgContainerButton"
                variant={"outline-light"}
                onClick={() => navigate(DEVICE_ROUTE + '/' + item.gameId)}
            >
                КУПИТЬ ИГРУ
            </Button></h1>
        
        </h3>
    </div>
        
        )}
</Carousel>

  </ConfigProvider>
  </div>
  </section>

    <HomeAbout/>
    <secttion>
    <h2 style={{textAlign: 'center'}}>ИГРЫ МАРБЛ ХАУС</h2>
    <section className='GamesContainerHome'><DeviceList/></section>
    <div style={{textAlign: 'center', marginBottom: '50px'}}>
    <Button className='GamesContainerHomeButton'
                variant={"dark"}
                onClick={() => navigate(SHOP_ROUTE)}
            >
                ПОКАЗАТЬ ВСЕ
    </Button></div>
    </secttion>
    <SubscribeBar/>
    </>
    );
});

export default Home;