import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom"
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PostsList from "../components/PostsList";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {fetchPosts} from "../http/blogAPI";
import {fetchDevices} from "../http/deviceAPI";
import PostsPages from "../components/PostsPages";
import DeviceList from '../components/DeviceList';
import '../stiles/Blog.css'
import Loader from '../components/Loader';
import { Alert } from 'antd';

const Blog = observer(() => {
    const {post} = useContext(Context)
    const {device} = useContext(Context)
    let loaded = false;
    

    useEffect(() => {
        fetchPosts(post.page, 12).then(data => {
            if (data) {
            post.setPosts(data.rows)
            post.setTotalCount(data.count)
            loaded = true;
                }
            })
        .catch((e) => {
            loaded = false;
             })
        .finally(() => {if (loaded) setLoading(false)})

        fetchDevices(null, 1, 3).then(data => {
            if (data) {
            device.setDevices(data.rows)
            device.setTotalCount(data.count)}
        })

    }, [post.page])


    const [loading, setLoading] = useState(true)

    if (loading) {
        return (<Loader/>); // отображение загрузки
      }
    return (
        <>
                    <section className='PostsContainerBlog'><PostsList/></section>
                    <div style={{display: 'grid'}}><div className='PostsPagination'><PostsPages/></div></div>
                    
                    <section style={{textAlign: 'center'}}>
                        <h2>ВОЗМОЖНО ВАС ЗАИНТЕРЕСУЕТ</h2>

                    <div className='GamesContainerBlog'><DeviceList/></div>
                    </section>
                    </>
    );
});

export default Blog;