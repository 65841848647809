import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import ListGroup from "react-bootstrap/ListGroup";
import { fetchDescriptions } from "../http/aboutAPI";
import Pages from "../components/Pages";
import SortBar from '../components/SortBar';
import Image from "react-bootstrap/Image"
import '../stiles/HomeAbout.css'
import batch from '../icons/batch.png'
import {  Avatar, Badge, Space, Drawer } from 'antd';
import {NavLink} from "react-router-dom";
import bag from '../icons/bag.png'
import { BAG_ROUTE} from "../utils/consts";
// import BagPage from '../pages/BagPage';


const Bag = observer(() => {

    // let cart = JSON.parse(localStorage.getItem("bag"));
    // let count = cart.length;

    const {cartStore} = useContext(Context)
    // const [countState, setCount] = useState(cartStore)
    
    // let updCount = () => {
    //     let cart = JSON.parse(localStorage.getItem("bag"));
    //     count = cart.length;
    // }

    // <BagPage updCount={updCount}/>

  
    return (
        <>
        <NavLink to={BAG_ROUTE}>
                        <Badge count={cartStore.cartCount}>
                            <img src={bag} style={{maxWidth: '30px'}}/>
                            </Badge>
                        </NavLink>

        </>
    );
     
});

export default Bag;


