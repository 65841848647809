import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import ListGroup from "react-bootstrap/ListGroup";
import { fetchDescriptions } from "../http/aboutAPI";
import Pages from "../components/Pages";
import SortBar from '../components/SortBar';
import Image from "react-bootstrap/Image"
import batch from '../icons/batch_opacity.png'
import '../stiles/GameplayAbout.css'
import Loader from './Loader';


const GameplayAbout = observer(() => {
    const {about} = useContext(Context)
    const [loading, setLoading] = useState(true)
    let loaded = false;


    useEffect(() => {
        fetchDescriptions()
        
        .then(data => {
            if (data && data.length > 0) {
                about.setDescriptions(data)
                loaded = true;
                }
            })
        .catch((e) => {
            loaded = false;
             })
        .finally(() => {if (loaded) setLoading(false)})
        
    }, [])

    
        if (loading) {
          return (<Loader/>); // отображение загрузки
        }
    return (
        <section className='gameplay_aboutContainer'>
            <article className='gameplay_aboutSection'>
            <h2>{about.descriptions[1].title}</h2>
            <div dangerouslySetInnerHTML={{__html: about.descriptions[1].text}}></div>
            </article>
            <div className='gameplay_aboutSectionImg'>
            <img src={batch}/>
            </div>
        </section>
    );
     
});

export default GameplayAbout;