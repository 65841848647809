import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom"
import {DEVICE_ROUTE} from "../utils/consts";
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TypeBar from "../components/TypeBar";
import DeviceList from "../components/DeviceList";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import { fetchDevices, fetchTypes} from "../http/deviceAPI";
import Pages from "../components/Pages";
import GameplayRounds from '../components/GameplayRounds';
import Footer from '../components/Footer';
import GameplayAbout from '../components/GameplayAbout';
import ByNow from '../components/ByNow';
import '../stiles/Bag.css'
import { ConfigProvider, Button, Modal, Space, Flex } from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import EmptyBag from '../components/EmptyBag';
import Bag from '../components/Bag';
// import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {createSendOrder} from "../http/orderAPIy";
import { Spin } from 'antd';

const BagPage = observer(() => {

    const navigate = useNavigate()

    const {cartStore} = useContext(Context);
    const [createOrder, setCreateOrder] = useState(false);
    const [value, setValue] = useState('')
    const [emailError, setEmailError] = useState('')
    const [orderSended, setOrderSended] = useState(false)
    const [orderNum, setOrderNum] = useState('')
    const [processing, setProcessing] = useState('')


    let cart = JSON.parse(localStorage.getItem("bag"));
    let sum = 0;
    let gamesList = [];

    if(cart) {
        for (const game of cart ) {
            sum += +JSON.parse(game).price;
          }
    }
    
    const [bag, setBag] = useState(cart);

    if (bag) {
        bag.map (game => {
            gamesList.push(JSON.parse(game).name)
        })
    }



    const deleteBag = (game) => {
        let bag = JSON.parse(localStorage.getItem("bag"));
        bag = bag || [];
        let newBag = [];
        bag = bag.map(function (el) {
            if (el != game) {
                newBag.push(el)
            }
            
          })

        window.localStorage.setItem('bag', JSON.stringify(newBag));
        setBag(newBag);
        cartStore.setCartCount(newBag.length);

    }

    const sendOrder = () => {
   
            if (!value) {
                setEmailError('Поле обязательно к заполнению')
                return;
              }
              setProcessing('Идет обработка, подождите...')
              setEmailError('')
              createSendOrder({email: value, games: gamesList, summa: sum})
              .then(data => {
                switch (data.status) {
                    case '201':
                        setOrderSended(true)
                        setOrderNum(data.neworder)
                        setValue('')
                        cartStore.setCartCount(0);
                        localStorage.removeItem('bag')
                        break;
                    case 400:
                        setEmailError('Ошибка. Проверьте правильность адреса электронной почты.')
                        setProcessing('')
                        break;
                    default:
                        setEmailError('Возникла непредвиденная ошибка. Пожалуйст повторите попытку позже.')
                        setProcessing('')
                        break;
                }
            })
            .catch((e) => {
                setEmailError('Возникла непредвиденная ошибка. Пожалуйст повторите попытку позже.');
                setProcessing('')
                 })
        }


    if (orderSended) {
            return (
                <>
            <section  className='EmptyBagContainer'>
        <article className='EmptyBagByNow'>
                <h2>Заказ №{orderNum} - успешно отправлен</h2>
                <p>На Вашу почту выслано письмо с указаниями по оплате заказа.</p>
                <p>Пожалуйста, проверьте Вашу почту.</p>

        </article>
            </section>
            </>
                
            );
        }

    if (!cart || cart.length == 0) {
        return (
            <>
                <EmptyBag/>
                </>
        );
    }

    return (
        <>
        <div className='bag'>
        <section>
        {bag.map(  game =>
        <article className="bagItem">
        <figure className="bagCover"
        onClick={() => navigate(DEVICE_ROUTE + '/' + JSON.parse(game).id)} 
        ><img className="bagImgCover" src={process.env.REACT_APP_API_URL + JSON.parse(game).img} alt='МАРБЛ ХАУС'/>
        </figure>
        <div>МАРБЛ ХАУС
        <h3 onClick={() => navigate(DEVICE_ROUTE + '/' + JSON.parse(game).id)}
        style={{cursor: 'pointer'}}>{JSON.parse(game).name.toUpperCase()}</h3>
        Цена: {JSON.parse(game).price}р.<br/>
        КОЛИЧЕСТВО ИГРОКОВ: {JSON.parse(game).gamers}<br/>
        <ConfigProvider
                theme={{
                    components: {
                        Button: {
                            borderRadius: 0,
                            colorPrimary: '#4B0082',
                            defaultActiveBorderColor: 'black',
                            algorithm: true, // Enable algorithm
                    }
                    },
                }}
                >
                <Space>
                    <Button type="text" onClick={() => deleteBag(game)} block><DeleteOutlined />
                    </Button>
                </Space>
                </ConfigProvider>
        </div>
        </article>
            )}

        </section>

        <aside className="checkOutAside">
        <div className="checkOut">
        <div className="bagSubtotal">
        <p>Сумма заказа:</p>
        {sum} р.
        </div>
        
        
        <ConfigProvider
                theme={{
                    components: {
                        Button: {
                            borderRadius: 0,
                            colorPrimary: 'black',
                            defaultActiveBorderColor: 'black',
                            algorithm: true, // Enable algorithm
                    }
                    },
                }}
                >
                <Space>
                <div className="checkOutButton">
                    
                    {createOrder ? 
                        <>
                        <p>Для оформления заказа необходимо ввести Вашу почту.</p>
                        <p style={{color: 'red'}}>{emailError}</p>
                        <p style={{color: 'blue'}}>{processing}</p>
                                <Form.Control style={{borderRadius: '0px'}}
                                    value={value}
                                    onChange={e => setValue(e.target.value)}
                                    placeholder="Введите Вашу почту"
                                    /><br/>
                                    <Button type="primary" onClick={()=>sendOrder()}>
                                    ОТПРАВИТЬ ЗАКАЗ
                                    </Button>
                                    </>            
                       
                        :
                    <Button type="primary" block onClick={()=>setCreateOrder(true)}>ОФОРМИТЬ ЗАКАЗ
                    </Button>
                        }
                    </div>
                </Space>
                </ConfigProvider>
                </div>
        </aside>
        </div>
        </>
    );
});

export default BagPage;