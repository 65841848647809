import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {Row, Button} from "react-bootstrap";
import RandomPostItem from "./RandomPostItem";
import {useNavigate} from 'react-router-dom'
import {ADMIN_ROUTE, LOGIN_ROUTE, SHOP_ROUTE, HOME_ROUTE, GAMEPLAY_ROUTE, BLOG_ROUTE} from "../utils/consts";
import '../stiles/RandomPostList.css'

const RandomPostsList = observer(() => {
    const {post} = useContext(Context)
    const navigate = useNavigate()

    return (
        <>
        <section style={{textAlign: 'center', marginTop: '50px'}}>
        <h2>ВОЗМОЖНО ВАС ТАКЖЕ ЗАИНТЕРЕСУЕТ</h2>
        <Button
                            variant={"outline-dark"}
                            size="sm"
                            onClick={() => navigate(BLOG_ROUTE)}
                            className='ByNowButton'
                        >ПРОСМОТРЕТЬ ВСЕ</Button>
        <section className='RandomPostList'>
            {post.posts.map(post =>
                <RandomPostItem key={post.id} post={post}/>
            )}
        </section>
        </section>
        </>
    );
});

export default RandomPostsList;