import {$authHost, $host} from "./index";
import {jwtDecode as jwt_decode} from "jwt-decode";

// export const registration = async (email, password) => {
//     const {data} = await $host.post('api/admin/registration', {email, password})
//     localStorage.setItem('token', data.token)
//     return jwt_decode(data.token)
// }

export const login = async (email, password) => {
    try {
        const {data} = await $host.post('api/admin/login', {email, password})
        localStorage.setItem('token', data.token)
        return jwt_decode(data.token)
    } catch (error) {
       alert (error.response.data)
    }  
}

export const check = async () => {
    try {
        const {data} = await $authHost.get('api/admin/auth' )
         localStorage.setItem('token', data.token)
        return jwt_decode(data.token)
    } catch (error) {
        // console.log('Ошибка авторизации - ' + error.response.data.message)
    }
}