import React, {useContext, useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {Form, Button,  Dropdown} from "react-bootstrap";
import {deleteGame, fetchDevices, fetchTypes} from "../../http/deviceAPI";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

const DeleteGame = observer(({show, onHide}) => {
    const [value, setValue] = useState('')
    const {device} = useContext(Context)

    const delGame = () => {
        deleteGame(device.selectedGame.id)
        .then(data => {if (data) {alert(data.message);
            window.location.reload()}})

    };


    useEffect(() => {
        fetchTypes().then(data => device.setTypes(data))
        fetchDevices(null, 0, 10000).then(data => {
            device.setDevices(data.rows)
        })
    }, [])

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Удалить игру
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Dropdown className="mt-2 mb-2">
                        <Dropdown.Toggle>{device.selectedGame.name || "Выберите игру"}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {device.devices.map(game =>
                                <Dropdown.Item
                                    onClick={() => 
                                        device.setSelectedGame(game) 
                                    }
                                    key={game.id}
                                    
                                >
                                    {game.name}
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-danger" onClick={onHide}>Закрыть</Button>
                <Button variant="outline-success" onClick={delGame}>Удалить</Button>
            </Modal.Footer>
        </Modal>
    );
});

export default DeleteGame;