import React, {useContext, useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {Button, Dropdown, Form, Row, Col} from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import {Context} from "../../index";
import {createMail} from "../../http/mailAPI";
import {observer} from "mobx-react-lite";

const CreateMail = observer(({show, onHide}) => {
    // const {post} = useContext(Context)
    const [title, setName] = useState('')
    const [text, setText] = useState('')

    



    const addMail = () => {
        const formData = new FormData()
        formData.append('title', title)
        formData.append('text', text)
        createMail(formData)
        .then(data => {if (data) {alert(data.message); 
            setName('');
            setText('');
            onHide()}}) 
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Отправить письмо
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>

                    <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label style = {{marginBottom: '0px', paddingBottomBottom: '0px'}}>Заголовок письма</Form.Label>
                    <Form.Control
                        value={title}
                        onChange={e => setName(e.target.value)}
                        className="mt-3"
                        placeholder="Введите заголовок письма"
                        style = {{marginTop: '0px'}}
                    /></Form.Group>
                    <hr/>         


                    <Form.Label>Текст письма</Form.Label>
                        <Form.Control
                        value={text}
                        onChange={e => setText(e.target.value)}
                        as="textarea"
                        placeholder="Введите текст письма"
                        style={{ height: '100px' }}
                        />
                    <hr/>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-success" onClick={addMail}>Добавить</Button>
                <Button variant="outline-danger" onClick={onHide}>Закрыть</Button>  
            </Modal.Footer>
        </Modal>
    );
});

export default CreateMail;