import {makeAutoObservable} from "mobx";

export default class AboutStore {
    constructor() {
        this._descriptions = []
        // this._page = 1
        // this._totalCount = 0
        // this._limit = 4
        makeAutoObservable(this)
    }

    setDescriptions(descriptions) {
        this._descriptions = descriptions
    }



    get descriptions() {
        return this._descriptions
    }
   
}