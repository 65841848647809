import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom"
import { Card, Col, Container, Image, Row} from "react-bootstrap";

import {useParams} from 'react-router-dom'
import {fetchOneDevice} from "../http/deviceAPI";
import { ConfigProvider, Button, Modal, Space, Flex } from 'antd';
import {BAG_ROUTE, SHOP_ROUTE} from "../utils/consts";
import Loader from '../components/Loader';
import {observer} from "mobx-react-lite";
import {Context} from "../index";

const DevicePage = () => {

    const {cartStore} = useContext(Context)
    const [device, setDevice] = useState({info: []})
    const {id} = useParams()

    let [inCart, setInCart] = useState(false);
    let inBag = false;
    const [loading, setLoading] = useState(true)
    let loaded = false;

    useEffect(() => {
        fetchOneDevice(id).then(data => {
            if (data) {
                setDevice(data)
                loaded = true;
                }
        })
        .catch((e) => {
            loaded = false;
             })
        .finally(() => {if (loaded) setLoading(false)})
        
    }, [])

    const navigate = useNavigate()


    let bag = JSON.parse(localStorage.getItem("bag"));
    bag = bag || [];
    const game = JSON.stringify(device);

    for (var i = 0; i < bag.length; i++) {
        if (bag[i] == game) {
            inBag = true;
        }
    }

    const addToBag = () => {
        
        setInCart(true)
        if (!inBag)
        bag.push(game);
        cartStore.setCartCount(bag.length)
        window.localStorage.setItem('bag', JSON.stringify(bag));
        
    }

    if (loading) {
        return (<Loader/>); // отображение загрузки
      }
    return (
        <Container className="mt-3">
            <ConfigProvider
                theme={{
                    components: {
                        Modal: {
                            borderRadiusLG: 0,
                            borderRadiusSM: 0,
                            algorithm: true, // Enable algorithm
                    },
                        Button: {
                            borderRadius: 0,
                            colorPrimary: '#4B0082',
                            defaultActiveBorderColor: 'black',
                            algorithm: true, // Enable algorithm
                    }
                    },
                }}
                >
                <Space>
                    
                        
                        <section className='ModalGameContainer'>

                        <div className='ModalImgСontainer'>
                        <img className="ModalImgСover" src={process.env.REACT_APP_API_URL + device.img}/>
                        </div>

                        <article className='ModalDescriptionСontainer'>

                        <article><p className='ModalLogo'>МАРБЛ ХАУС</p> 
                        <h2 className='ModalDescriptionСontainerH2'>{device.name.toUpperCase()}</h2>
                        <p className='ModalDescriptionСontainerPrice'>{device.price} p.</p>
                        <hr/>
                        <p>КОЛИЧЕСТВО ИГРОКОВ: {device.gamers}</p>
                        <p>{device.description}</p>
                        </article>
                        
                        <div className='ModalButtons'>

                        <Flex vertical gap="small" style={{ width: '100%' }}>
                            {inCart || inBag ?
                            <>
                            <div style={{ textAlign: 'center', padding: '10px', fontWeight: 'bold' }}>ИГРА ДОБАВЛЕНА В КОРЗИНУ</div>
                            <Button block type="primary" onClick={() => navigate(BAG_ROUTE)}>ПЕРЕЙТИ В КОРЗИНУ</Button> 
                            </> 
                            :
                            <Button type="primary" onClick={addToBag} block>КУПИТЬ ИГРУ</Button>
                            }
                            <Button block onClick={() => navigate(SHOP_ROUTE)}>МАГАЗИН</Button>
                        </Flex>


                        
                        
                        </div>
                        

                        </article>

                        </section>


                    
                </Space>
                </ConfigProvider>
            
        </Container>
    );
};

export default DevicePage;