import React from 'react';
import {Card, Col} from "react-bootstrap";
import Image from "react-bootstrap/Image";

import {useNavigate} from "react-router-dom"
import {POST_ROUTE} from "../utils/consts";
import '../stiles/PostList.css'

const RandomPostItem = ({post}) => {
    const navigate = useNavigate()

    const formatter = new Intl.DateTimeFormat("ru", {
        year: "numeric",
        month: "long",
        day: "2-digit"
      });
    return (
        <article
        onClick={() => navigate(POST_ROUTE + '/' + post.id)+(window.location.reload())}
        >
            <Card style={{cursor: 'pointer', textAlign: 'center'}} border={"light"}>
                <Image className='ImgСoverPost' src={process.env.REACT_APP_API_URL + post.img}/>
                <div>{formatter.format(Date.parse(post.createdAt)).substring(0, formatter.format(Date.parse(post.createdAt)).length - 3)}</div>
                <div>{post.name}</div>
                <div className="text-black-50 mt-1 d-flex justify-content-between align-items-center">
                <div>{post.description}</div>
                </div> 
                
                
            </Card>
        </article>
    );
};

export default RandomPostItem;