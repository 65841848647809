import React, {useContext, useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import '../stiles/SubscriberBar.css'
import {createSubscriber} from "../http/subscribersAPI";
 
const SubsribeBar = observer(() => {
  const [value, setValue] = useState('')
  const [emailError, setEmailError] = useState('')
  const [response, setResponse] = useState('')


  const addSubscriber = () => {
    if (!value) {
      setEmailError('Поле обязательно к заполнению')
      setResponse('')
      return;
    }
    createSubscriber({email: value})
    .then(data => {
      if (data) {
        setResponse(data.message)
        setEmailError('')
        setValue('')
      }  
    })
    .catch((e) => {
      setResponse('Ошибка сервера. Попробуйте повторить попытку позже.')
       })
}

    
    return (
        <section className='subscriber'>
        <h2>ОБРАТНАЯ СВЯЗЬ</h2>
        <p>Подпишитесь, чтобы получать специальные предложения</p>
        <p>{emailError}</p>
        <p>{response}</p>
        <InputGroup className="form">
        <Form.Control
          value={value}
          onChange={e => setValue(e.target.value)}
          placeholder="Введите Вашу почту"
        />
        <Button variant="dark" onClick={addSubscriber} style={{borderRadius: '0px'}}>
          ПОДПИСАТЬСЯ
        </Button>
        
      </InputGroup>

      </section>
    );
});

export default SubsribeBar;