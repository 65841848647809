export const ADMIN_ROUTE = '/admin'
export const LOGIN_ROUTE = '/login'
export const REGISTRATION_ROUTE = '/registration'
export const SHOP_ROUTE = '/shop'
export const DEVICE_ROUTE = '/game'
export const BLOG_ROUTE = '/posts'
export const POST_ROUTE = '/post'
export const GAMEPLAY_ROUTE = '/gamerlay'
export const BAG_ROUTE = '/bag'
export const SEARCH_ROUTE = '/search'
export const HOME_ROUTE = '/'
