import React, {useContext, useEffect, useState} from 'react';
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TypeBar from "../components/TypeBar";
import DeviceList from "../components/DeviceList";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import { fetchDevices, fetchTypes} from "../http/deviceAPI";
import Pages from "../components/Pages";
import GameplayRounds from '../components/GameplayRounds';
import Footer from '../components/Footer';
import GameplayAbout from '../components/GameplayAbout';
import ByNow from '../components/ByNow';

import SearchLoader from '../components/SearchLoader';
import SearchBar from '../components/SearchBar';
import {DEVICE_ROUTE, POST_ROUTE} from "../utils/consts";
import {useNavigate} from "react-router-dom"
import '../stiles/Search.css'

const Search = observer(() => {
    const navigate = useNavigate()
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)

    const childToParent = (childdata) => {
        setData(JSON.parse(childdata));
        setLoading(false)
    }
   
    if (loading) {
        return (
            <>
            <SearchBar childToParent={childToParent}/>
        <SearchLoader/></>
        );
      }

    return (
        <>
        <SearchBar childToParent={childToParent}/>
        <div className='search'>
        <section>
        {data.map( item =>
        <article className="searchItem">
        <figure className="searchCover"
        onClick={() => (item.typeOf == 'game') ? navigate(DEVICE_ROUTE + '/' + item.id) : navigate(POST_ROUTE + '/' + item.id)} 
        ><img className="searchImgCover" src={process.env.REACT_APP_API_URL + item.img}/>
        </figure>
        <div>
        <h3 style={{cursor: 'pointer'}}
        onClick={() => (item.typeOf == 'game') ? navigate(DEVICE_ROUTE + '/' + item.id) : navigate(POST_ROUTE + '/' + item.id)} 
        >{item.name.toUpperCase()}</h3>
        {item.description}
        </div>
        </article>
            )}

        </section>

        </div>
        </>
    );
});

export default Search;