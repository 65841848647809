import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import ListGroup from "react-bootstrap/ListGroup";
import '../stiles/ListGroup.css';

const TypeBar = observer(() => {
    const {device} = useContext(Context)
    return (
        <ListGroup>

                <ListGroup.Item
                    
                    action variant="dark"
                    className='list-group-item'
                    style={{cursor: 'pointer', borderRadius: '0'}}
                    active = {!device.selectedType.id}
                    onClick={() => device.setSelectedType('')}    
                >
                    Все игры
                </ListGroup.Item>

            {device.types.map(type =>
                <ListGroup.Item
                
                    action variant="dark"
                    className='list-group-item'
                    style={{cursor: 'pointer', borderRadius: '0'}}
                    active={type.id === device.selectedType.id}
                    onClick={() => device.setSelectedType(type)}
                    key={type.id}
                >
                    {type.name}
                </ListGroup.Item>
            )}

                
        </ListGroup>
    );
});

export default TypeBar;