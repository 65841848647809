import React, {useContext, useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {Button, Dropdown, Form, Row, Col} from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import {Context} from "../../index";
import {createPost, fetchDevices, fetchTypes} from "../../http/blogAPI";
import {observer} from "mobx-react-lite";

const CreatePost = observer(({show, onHide}) => {
    // const {post} = useContext(Context)
    const [name, setName] = useState('')
    const [text, setText] = useState('')
    const [description, setDescription] = useState('')
    const [file, setFile] = useState(null)
    

    // useEffect(() => {
    //     fetchTypes().then(data => device.setTypes(data))
        
    // }, [])



    const selectFile = e => {
        setFile(e.target.files[0])
    }

    const addDevice = () => {
        const formData = new FormData()
        formData.append('name', name)
        formData.append('text', text)
        formData.append('description', description)
        formData.append('img', file)
        console.log(formData)
        createPost(formData)
        .then(data => {if (data) {alert(data.message);
            window.location.reload()}}) 
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Добавить новость
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>

                    <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label style = {{marginBottom: '0px', paddingBottomBottom: '0px'}}>Название новости</Form.Label>
                    <Form.Control
                        value={name}
                        onChange={e => setName(e.target.value)}
                        className="mt-3"
                        placeholder="Введите заголовок новости"
                        style = {{marginTop: '0px'}}
                    /></Form.Group>
                    <hr/>         

                    <Form.Label>Описание новости</Form.Label>
                        <Form.Control
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        as="textarea"
                        placeholder="Введите описание новости"
                        style={{ height: '100px' }}
                        />
                    <hr/>

                    <Form.Label>Текст новости</Form.Label>
                        <Form.Control
                        value={text}
                        onChange={e => setText(e.target.value)}
                        as="textarea"
                        placeholder="Введите текст новости"
                        style={{ height: '100px' }}
                        />
                    <hr/>

                    <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Выберите изображение для обложки</Form.Label>
                    <Form.Control
                        type="file"
                        onChange={selectFile}
                    /></Form.Group>
                    <hr/>
                   

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-success" onClick={addDevice}>Добавить</Button>
                <Button variant="outline-danger" onClick={onHide}>Закрыть</Button>  
            </Modal.Footer>
        </Modal>
    );
});

export default CreatePost;