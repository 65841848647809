import {$authHost, $host} from "./index";


export const createStages = async (post) => {
    const {data} = await $authHost.post('api/stages', post)
    return data
}

export const fetchStages = async () => {
    const {data} = await $host.get('api/stages')
    return data
}

export const fetchOneStage = async (id) => {
    const {data} = await $host.get('api/stages/' + id)
    return data
}