import React, {useState} from 'react';
import {Button, Container} from "react-bootstrap";
import CreateDevice from "../components/modals/CreateDevice";
import CreateType from "../components/modals/CreateType";
import CreateBannerItem from "../components/modals/CreateBannerItem";
import DeleteType from "../components/modals/DeleteType";
import DeleteGame from "../components/modals/DeleteGame";
import DeleteBannerItem from "../components/modals/DeleteBannerItem";
import CreatePost from '../components/modals/CreatePost';
import DeletePost from '../components/modals/DeletePost';
import CreateMail from '../components/modals/CreateMail';


const Admin = () => {
    
    const [typeVisible, setTypeVisible] = useState(false)
    const [deviceVisible, setDeviceVisible] = useState(false)
    const [banneritemVisible, setBannerItemVisible] = useState(false)
    const [postVisible, setPostVisible] = useState(false)
    const [mailVisible, setMailVisible] = useState(false)
    const [delete_typeVisible, setDeleteTypeVisible] = useState(false)
    const [delete_gameVisible, setDeleteGameVisible] = useState(false)
    const [delete_banneritemVisible, setDeleteBannerItemVisible] = useState(false)
    const [delete_postVisible, setDeletePostVisible] = useState(false)
    

    return (
        <Container className="d-flex flex-column">
            <Button
                variant={"outline-dark"}
                className="mt-4 p-2"
                onClick={() => setMailVisible(true)}
            >
                Отправить письмо
            </Button>

            <Button
                variant={"outline-dark"}
                className="mt-4 p-2"
                onClick={() => setTypeVisible(true)}
            >
                Добавить тип игры
            </Button>

            <Button
                variant={"outline-dark"}
                className="mt-4 p-2"
                onClick={() => setDeleteTypeVisible(true)}
            >
                Удалить тип игры
            </Button>
            
            <Button
                variant={"outline-dark"}
                className="mt-4 p-2"
                onClick={() => setDeviceVisible(true)}
            >
                Добавить игру
            </Button>

            <Button
                variant={"outline-dark"}
                className="mt-4 p-2"
                onClick={() => setDeleteGameVisible(true)}
            >
                Удалить игру
            </Button>

            <Button
                variant={"outline-dark"}
                className="mt-4 p-2"
                onClick={() => setPostVisible(true)}
            >
                Добавить новость
            </Button>

            <Button
                variant={"outline-dark"}
                className="mt-4 p-2"
                onClick={() => setDeletePostVisible(true)}
            >
                Удалить новость
            </Button>

            <Button
                variant={"outline-dark"}
                className="mt-4 p-2"
                onClick={() => setBannerItemVisible(true)}
            >
                Добавить страницу банера
            </Button>

            <Button
                variant={"outline-dark"}
                className="mt-4 p-2"
                onClick={() => setDeleteBannerItemVisible(true)}
            >
                Удалить страницу банера
            </Button>
            
            <CreateDevice show={deviceVisible} onHide={() => setDeviceVisible(false)}/>
            <CreateType show={typeVisible} onHide={() => setTypeVisible(false)}/>
            <CreateBannerItem show={banneritemVisible} onHide={() => setBannerItemVisible(false)}/>
            <CreatePost show={postVisible} onHide={() => setPostVisible(false)}/>
            <CreateMail show={mailVisible} onHide={() => setMailVisible(false)}/>
            <DeleteType show={delete_typeVisible} onHide={() => setDeleteTypeVisible(false)}/>
            <DeleteGame show={delete_gameVisible} onHide={() => setDeleteGameVisible(false)}/>
            <DeleteBannerItem show={delete_banneritemVisible} onHide={() => setDeleteBannerItemVisible(false)}/>
            <DeletePost show={delete_postVisible} onHide={() => setDeletePostVisible(false)}/>
        </Container>
    );
};

export default Admin;