import {$authHost, $host} from "./index";


export const startSearch = async (keyword) => {
   try {
     const {data} = await $host.get('api/search', {params: {
         keyword
     }})
     return (data)
   } catch (error) {
        if (error.response.status == 400) {
            return (400)
        }
        return (500)
   }
}

