import Admin from "./pages/Admin";
import {ADMIN_ROUTE, SEARCH_ROUTE, BAG_ROUTE, DEVICE_ROUTE, LOGIN_ROUTE, SHOP_ROUTE, HOME_ROUTE, BLOG_ROUTE, GAMEPLAY_ROUTE, POST_ROUTE} from "./utils/consts";

import Shop from "./pages/Shop";
import Auth from "./pages/Auth";
import Blog from "./pages/Blog";
import Gameplay from "./pages/Gameplay";
import Home from "./pages/Home";
import DevicePage from "./pages/DevicePage";
import PostPage from "./pages/PostPage";
import BagPage from "./pages/BagPage";
import Search from "./pages/Search";

export const authRoutes = [
    {
        path: ADMIN_ROUTE,
        Element: Admin
    }
]

export const publicRoutes = [
    {
        path: HOME_ROUTE,
        Element: Home
    },
    {
        path: GAMEPLAY_ROUTE,
        Element: Gameplay
    },
    {
        path: SHOP_ROUTE,
        Element: Shop
    },
    {
        path: BLOG_ROUTE,
        Element: Blog
    },
    {
        path: LOGIN_ROUTE,
        Element: Auth
    },
    {
        path: DEVICE_ROUTE + '/:id',
        Element: DevicePage
    },
    {
        path: POST_ROUTE + '/:id',
        Element: PostPage
    },

    {
        path: BAG_ROUTE,
        Element: BagPage
    },

    {
        path: SEARCH_ROUTE,
        Element: Search
    },
]