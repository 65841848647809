import React, { useState, useEffect }  from 'react';
import {Container, Nav} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Card} from "react-bootstrap";
import TypeBar from "../components/TypeBar";
import DeviceList from "../components/DeviceList";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import { fetchDevices, fetchTypes} from "../http/deviceAPI";
import Pages from "../components/Pages";
import SortBar from '../components/SortBar';
import '../stiles/Footer.css'
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { ConfigProvider, Button, Modal, Space, Flex } from 'antd';
import { MailOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import {useNavigate} from 'react-router-dom'
import {ADMIN_ROUTE, LOGIN_ROUTE, SHOP_ROUTE, HOME_ROUTE, GAMEPLAY_ROUTE, BLOG_ROUTE} from "../utils/consts";
import telegram from '../icons/telegram.png'
import zen from '../icons/zen.png'
import { Menu } from 'antd';
import {createSubscriber} from "../http/subscribersAPI";



const Footer = observer(() => {
    const navigate = useNavigate()
    const[formValid, setFormValid] = useState('none')
    const[formUnvalid, setFormUnvalid] = useState('')
    const [emailError, setEmailError] = useState('(поле обязательно к заполнению)')

    // const[footerShow, setFooterShow] = useState('none')
    // const[footerClose, setFooterClose] = useState('')

    const [data, setData] = useState({
        email:""
    })

    const [isMobile, setIsMobile] = useState(false)
    const [isMobile1, setIsMobile1] = useState('footerSectionItemHide')
    const [upIcon1, setUpIcon1] = useState('futerMenuIconDownHide')
    const [downIcon1, setDownIcon1] = useState('futerMenuIconDown')
    const [isMobileShow1, setIsMobileShow1] = useState(false)
    const [isMobile2, setIsMobile2] = useState('footerSectionItemHide')
    const [upIcon2, setUpIcon2] = useState('futerMenuIconDownHide')
    const [downIcon2, setDownIcon2] = useState('futerMenuIconDown')
    const [isMobileShow2, setIsMobileShow2] = useState(false)
    const [isMobile3, setIsMobile3] = useState('footerSectionItemHide')
    const [upIcon3, setUpIcon3] = useState('futerMenuIconDownHide')
    const [downIcon3, setDownIcon3] = useState('futerMenuIconDown')
    const [isMobileShow3, setIsMobileShow3] = useState(false)
    const [isMobile4, setIsMobile4] = useState('footerSectionItemHide')
    const [upIcon4, setUpIcon4] = useState('futerMenuIconDownHide')
    const [downIcon4, setDownIcon4] = useState('futerMenuIconDown')
    const [isMobileShow4, setIsMobileShow4] = useState(false)
 
   
    const handleResize = () => {
        
    }

    const footerShow1 = () => {
        
        if (!isMobileShow1) {
            setIsMobile1('footerSectionItemVisible')
            setIsMobileShow1(true)
            setUpIcon1('futerMenuIconDown')
            setDownIcon1('futerMenuIconDownHide')
        } else {
            setIsMobile1('footerSectionItemHide')
            setIsMobileShow1(false)
            setUpIcon1('futerMenuIconDownHide')
            setDownIcon1('futerMenuIconDown')
        }}

    const footerShow2 = () => {
        
        if (!isMobileShow2) {
            setIsMobile2('footerSectionItemVisible')
            setIsMobileShow2(true)
            setUpIcon2('futerMenuIconDown')
            setDownIcon2('futerMenuIconDownHide')
        } else {
            setIsMobile2('footerSectionItemHide')
            setIsMobileShow2(false)
            setUpIcon2('futerMenuIconDownHide')
            setDownIcon2('futerMenuIconDown')
        }}

    const footerShow3 = () => {
        
        if (!isMobileShow3) {
            setIsMobile3('footerSectionItemVisible')
            setIsMobileShow3(true)
            setUpIcon3('futerMenuIconDown')
            setDownIcon3('futerMenuIconDownHide')
        } else {
            setIsMobile3('footerSectionItemHide')
            setIsMobileShow3(false)
            setUpIcon3('futerMenuIconDownHide')
            setDownIcon3('futerMenuIconDown')
        }}
            
    const footerShow4 = () => {
        
        if (!isMobileShow4) {
            setIsMobile4('footerSectionItemVisible')
            setIsMobileShow4(true)
            setUpIcon4('futerMenuIconDown')
            setDownIcon4('futerMenuIconDownHide')
        } else {
            setIsMobile4('footerSectionItemHide')
            setIsMobileShow4(false)
            setUpIcon4('futerMenuIconDownHide')
            setDownIcon4('futerMenuIconDown')
        }}
        


    useEffect(() => {
        window.addEventListener("resize", handleResize)
        if (emailError) {
            setFormValid('none')
            setFormUnvalid('')
        } else {
            setFormValid('')
            setFormUnvalid('none')
        }
    }, [emailError] )

    const emailHandler = (e) => {
        setData({ ...data, email:e.target.value});
        const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!re.test(String(e.target.value).toLowerCase())) {
            setEmailError('(некорректный адресс электронной почты)')
            if (!e.target.value) setEmailError('(поле обязательно к заполнению)')
        }
        else{
            setEmailError('')
            
        }
        
    }

    const submitForm = async (e) => {
        e.preventDefault();
        const sendData = {
            email:data.email,
        }
        createSubscriber(sendData)
        .then(data => {
            if (data) {
                alert(data.message)
                setData({ ...data, email:''})
                setFormValid('none')
                setFormUnvalid('')
            } 
        })
        .catch((e) => {
            alert('Ошибка сервера. Попробуйте повторить попытку позже.')
             })
        
    }

   

    return (
        <>
        <hr/>
        <footer >
            
            <article className='footerSection'>
            <h2 onClick={footerShow1}>КОНТАКТЫ  <DownOutlined className={downIcon1}/> 
            <UpOutlined className={upIcon1}/></h2>
                <div className={isMobile1}>
                    <Alert variant='light'>
                    Мы в Телеграм 
                    <Alert.Link href='https://t.me/cludintelligence'> cludintelligence</Alert.Link>
                    </Alert>
                </div>
            </article>
            
            <article className='footerSection'>
            <h2 onClick={footerShow2}>ССЫЛКИ <DownOutlined className={downIcon2}/> 
            <UpOutlined className={upIcon2}/></h2>
            <div className={isMobile2}>
            <Nav className="footerlink">
                <NavLink  to={GAMEPLAY_ROUTE} >Игровой процесс</NavLink>
                <NavLink  to={SHOP_ROUTE}>Магазин</NavLink>
                <NavLink  to={BLOG_ROUTE}>Блог</NavLink>
             </Nav></div>
            </article>

            <article className='footerSection'>
            <h2 onClick={footerShow3}>ПОДПИШИТЕСЬ<DownOutlined className={downIcon3}/> 
            <UpOutlined className={upIcon3}/></h2>
            <div className={isMobile3}>
            Подпишитесь, чтобы получать специальные предложения и быть в курсе всех обновлений.
            <InputGroup className='footerFormSubscriber'>
                <Form.Control
                placeholder="Введите Вашу почту"
                className='footerInputSubscriber'
                onChange= {e => emailHandler(e)} 
                value = {data.email}
                />
                <ConfigProvider
                theme={{
                    components: {
                       
                        Button: {
                            borderRadius: 0,
                            colorPrimary: '#4B0082',
                            textHoverBg: 'none',
                            activeBorderColor: 'white',
                            algorithm: true, // Enable algorithm
                    }
                    },
                }}
                >
                <Space>
                    
                            <MailOutlined style={{display: formUnvalid}}/>
                            <Button type="text" style={{display: formValid}} onClick={submitForm}>Подписаться</Button>
                </Space>
                </ConfigProvider>
            </InputGroup>
                <nav className='footer_icons'>
                    <NavLink  to='https://t.me/cludintelligence'><img src={telegram}/></NavLink>
                    <NavLink  to='https://dzen.ru/id/65cb31dd9472bd5004721781'><img src={zen}/></NavLink>
                </nav></div>
            </article>

            <article className='footerSection'>
            <h2 onClick={footerShow4}>НАШИ ТОВАРЫ<DownOutlined className={downIcon4}/> 
            <UpOutlined className={upIcon4}/></h2>
            <div className={isMobile4}>
                Обратите внимание, что все наши товары являются цифровыми. Файлы становятся доступными вам сразу после покупки и предназначены как для цифрового использования, так и для печати. Никакие продукты вам не будут физически отправлены.
                </div></article>
        </footer>
            {/* <Menu
                mode="inline"
                items={[
                    {label: 'НАШИ ТОВАРЫ', key: '/Contacts 1', children: [{label: 'Обратите внимание, что все наши товары являются цифровыми. Файлы становятся доступными вам сразу после покупки и предназначены как для цифрового использования, так и для печати. Никакие продукты вам не будут физически отправлены.', key: '/Contacts 3',}]},
                    {label: 'Contacts 2', key: '/Contacts 2',}
                ]}
                
        /> */}
        </>
    );
});

export default Footer;