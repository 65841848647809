import React, {useContext, useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {Button, Dropdown, Form, Row, Col} from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import {Context} from "../../index";
import {createDevice, fetchDevices, fetchTypes} from "../../http/deviceAPI";
import {observer} from "mobx-react-lite";

const CreateDevice = observer(({show, onHide}) => {
    const {device} = useContext(Context)
    const [name, setName] = useState('')
    const [price, setPrice] = useState(0)
    const [gamers, setGamers] = useState(0)
    const [level, setLevel] = useState(0)
    const [description, setDescription] = useState('')
    const [file, setFile] = useState(null)
    

    useEffect(() => {
        fetchTypes().then(data => device.setTypes(data))
        
    }, [])



    const selectFile = e => {
        setFile(e.target.files[0])
    }

    const addDevice = () => {
        const formData = new FormData()
        formData.append('name', name)
        formData.append('price', `${price}`)
        formData.append('gamers', `${gamers}`)
        formData.append('level', `${level}`)
        formData.append('rating', `${level}`)
        formData.append('description', description)
        formData.append('img', file)
        formData.append('typeId', device.selectedType.id)
        createDevice(formData)
        .then(data => {if (data) {alert(data.message);
            window.location.reload()}}) 
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Добавить устройство
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>

                    <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label style = {{marginBottom: '0px', paddingBottomBottom: '0px'}}>Название игры</Form.Label>
                    <Form.Control
                        value={name}
                        onChange={e => setName(e.target.value)}
                        className="mt-3"
                        placeholder="Введите название игры"
                        style = {{marginTop: '0px'}}
                    /></Form.Group>
                    <hr/>

                    <Dropdown className="mt-2 mb-2">
                        <Dropdown.Toggle>{device.selectedType.name || "Выберите тип игры"}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {device.types.map(type =>
                                <Dropdown.Item
                                    onClick={() => device.setSelectedType(type)}
                                    key={type.id}
                                >
                                    {type.name}
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                    <hr/>



                    <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Введите стоимость игры</Form.Label>
                    <Form.Control
                        value={price}
                        onChange={e => setPrice(Number(e.target.value))}
                        className="mt-3"
                        type="number"
                    /></Form.Group>
                    <hr/>

                    <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Введите количество игроков</Form.Label>
                    <Form.Control
                        value={gamers}
                        onChange={e => setGamers(Number(e.target.value))}
                        className="mt-3"
                        type="number"
                    /></Form.Group>
                    <hr/>

                    <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Введите уровень сложности игры</Form.Label>
                    <Form.Control
                        value={level}
                        onChange={e => setLevel(Number(e.target.value))}
                        className="mt-3"
                        type="number"
                    /></Form.Group>
                    <hr/>

                

                    <Form.Label>Описание игры</Form.Label>
                        <Form.Control
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        as="textarea"
                        placeholder="Введите описание игры"
                        style={{ height: '100px' }}
                        />
                    <hr/>

                    <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Выберите изображение для обложки</Form.Label>
                    <Form.Control
                        type="file"
                        onChange={selectFile}
                    /></Form.Group>
                    <hr/>
                   

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-success" onClick={addDevice}>Добавить</Button>
                <Button variant="outline-danger" onClick={onHide}>Закрыть</Button>  
            </Modal.Footer>
        </Modal>
    );
});

export default CreateDevice;