import React, {useContext, useEffect, useState} from 'react';
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TypeBar from "../components/TypeBar";
import DeviceList from "../components/DeviceList";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import { fetchDevices, fetchTypes} from "../http/deviceAPI";
import Pages from "../components/Pages";
import SortBar from '../components/SortBar';
import Image from 'react-bootstrap/Image';
import '../stiles/Shop.css'
import Loader from '../components/Loader';

const Shop = observer(() => {
    const {device} = useContext(Context)
    const [loading, setLoading] = useState(true)
    let loaded = false;

    useEffect(() => {
        fetchTypes().then(data => device.setTypes(data))
        fetchDevices(device.selectedType.id, device.page, 12, device.selectedOrder).then(data => {
        if (data) {
            device.setDevices(data.rows)
            device.setTotalCount(data.count)
            loaded = true;
            }
        })
        .catch((e) => {
            loaded = false;
            })
        .finally(() => {if (loaded) setLoading(false)})
    }, [device.page, device.selectedType, device.selectedOrder])

    if (loading) {
        return <Loader/>
    }

    return (
        <>

            <figure className="ShopbannerImgContainer">
                <Image style={{objectFit: 'cover', width: '100%', height: '30vw'}} src={process.env.REACT_APP_API_URL + 'board.jpg'}  />
                <figcaption className="ShopbannerImgContainerText">ИГРЫ МАРБЛ ХАУС</figcaption>
            </figure>
        
        <section className="ShopContainer">
            <Row className="mt-2">
                <Col md={3}>
                    <TypeBar/>
                </Col>
                <Col md={9}>
                    <SortBar/>
                    <section className='GamesContainerShop'><DeviceList/></section>
                    <div style={{display: 'grid'}}><div className='ShopPagination'><Pages/></div></div>
                </Col>
            </Row>
        </section>
        </>
    );
});

export default Shop;