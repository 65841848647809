import {$authHost, $host} from "./index";


// export const createDescription = async (post) => {
//     const {data} = await $authHost.post('api/descriptions', post)
//     return data
// }

export const fetchDescriptions = async (page, limit) => {
    try {
        const {data} = await $host.get('api/descriptions', {params: {
            page, limit
        }})
        return data
    } catch (error) {
        return
    }
}

export const fetchOneDescription = async (id) => {
    try {
        const {data} = await $host.get('api/descriptions/' + id)
        return data
    } catch (error) {
        return
    }
}