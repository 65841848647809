import React, {useContext} from 'react';
import { Route, Routes, BrowserRouter as Router} from 'react-router-dom'
import {authRoutes, publicRoutes} from "../routes";
import {Context} from "../index";
import {observer} from "mobx-react-lite";


const AppRouter = observer(() => {
    const {user} = useContext(Context)

    return (   
        <Routes>
            {user.isAuth && authRoutes.map(({path, Element}) =>
                <Route key={path} exact path={path} element={<Element/>}/>
            )}
            {publicRoutes.map(({path, Element}) =>
                <Route key={path} exact path={path} element={<Element/>}/>
            )} 
        </Routes>
        
    );
});

export default AppRouter;