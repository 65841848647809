import React, {useContext, useState} from 'react';
import { Card, Col} from "react-bootstrap";
import Image from "react-bootstrap/Image";
import star from '../assets/star.png'
import {useNavigate} from "react-router-dom"
import {BAG_ROUTE, DEVICE_ROUTE} from "../utils/consts";
import '../stiles/GameModal.css'
import {observer} from "mobx-react-lite";
import {Context} from "../index";


// import Modal from "react-bootstrap/Modal";
import { ConfigProvider, Button, Modal, Space, Flex, Alert } from 'antd';

const DeviceItem = observer(({device}) => {

    const {cartStore} = useContext(Context)
    
    const navigate = useNavigate()
    let [gameVisible, setGameVisible] = useState(false)

    let [inCart, setInCart] = useState(false);
    let inBag = false;

    const handleClose = () => setGameVisible(false);
    const handleShow = () => {setGameVisible(true)};

    let bag = JSON.parse(localStorage.getItem("bag"));
        bag = bag || [];
        const game = JSON.stringify(device);

        for (let i = 0; i < bag.length; i++) {
            if (bag[i] == game) {
                inBag = true;
            }
        }

    const addToBag = () => {
        
        setInCart(true)
        if (!inBag)
        bag.push(game);
        cartStore.setCartCount(bag.length)
        window.localStorage.setItem('bag', JSON.stringify(bag));
        
    }

    return (<>
        <article>
            <Card style={{cursor: 'pointer'}} border={"light"} onClick={handleShow}>
                <img className="ImgСover" src={process.env.REACT_APP_API_URL + device.img}/>
                
                <div>{device.name.toUpperCase()}</div>
                <div className="text-black-50">МАРБЛ ХAУС</div>
                <div className="mt-1 d-flex justify-content-between align-items-center">
                <div>{device.price}p.</div>
                    
                    <div className="d-flex align-items-center">
                        <div style={{fontSize: '1.3rem', fontWeight: '500', marginRight: '4px'}}>{device.level} </div>
                        <Image width={25} height={25} src={star}/>
                    </div>
                </div>  
            </Card>
            <ConfigProvider
                theme={{
                    components: {
                        Modal: {
                            borderRadiusLG: 0,
                            borderRadiusSM: 0,
                            algorithm: true, // Enable algorithm
                    },
                        Button: {
                            borderRadius: 0,
                            colorPrimary: '#4B0082',
                            defaultActiveBorderColor: 'black',
                            algorithm: true, // Enable algorithm
                    }
                    },
                }}
                >
                <Space>
                    <Modal className='ant-modal'
                        open={gameVisible}
                        onCancel={handleClose}
                        width={1200}
                        centered
                        closable = {false}
                        footer = {null}
                        maskStyle={{ backgroundColor: "black", opacity: '0.9' }} 
                         
                    >
                        
                        <section className='ModalGameContainer'>

                        <div className='ModalImgСontainer'>
                        <img className="ModalImgСover" src={process.env.REACT_APP_API_URL + device.img}/>
                        </div>

                        <article className='ModalDescriptionСontainer'>

                        <article><p className='ModalLogo'>МАРБЛ ХАУС</p> 
                        <h2 className='ModalDescriptionСontainerH2'>{device.name.toUpperCase()}</h2>
                        <p className='ModalDescriptionСontainerPrice'>{device.price} p.</p>
                        <hr/>
                        <p>КОЛИЧЕСТВО ИГРОКОВ: {device.gamers}</p>
                        <p>{device.description}</p>
                        </article>
                        
                        <div className='ModalButtons'>
                               
                            <Flex vertical gap="small" style={{ width: '100%' }}>
                            {inCart || inBag ?
                            <>
                            <div style={{ textAlign: 'center', padding: '10px', fontWeight: 'bold' }}>ИГРА ДОБАВЛЕНА В КОРЗИНУ</div>
                            <Button block type="primary" onClick={() => navigate(BAG_ROUTE)}>ПЕРЕЙТИ В КОРЗИНУ</Button> 
                            </> 
                            :
                            <Button type="primary" onClick={addToBag} block>КУПИТЬ ИГРУ</Button>
                            }
                            <Button block onClick={handleClose}>МАГАЗИН</Button>
                        </Flex>
                        
                        
                        </div>
                        

                        </article>

                        </section>
                        


                    </Modal>
                </Space>
                </ConfigProvider>   
        </article>

            
                </>
        
    );
});

export default DeviceItem;