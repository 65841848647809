import React, {useContext, useState} from 'react';
import {Context} from "../../index";
// import Navbar from "react-bootstrap/Navbar";
// import Nav from "react-bootstrap/Nav";
import { Container, FormControl, Navbar, Nav, Form, Button } from 'react-bootstrap';
import {NavLink} from "react-router-dom";
import {ADMIN_ROUTE, LOGIN_ROUTE, SHOP_ROUTE, HOME_ROUTE, GAMEPLAY_ROUTE, BLOG_ROUTE, SEARCH_ROUTE, BAG_ROUTE} from "../../utils/consts";
// import {Button, Form, FormControl} from "react-bootstrap";
import {observer} from "mobx-react-lite";
// import Container from "react-bootstrap/Container";
import {useNavigate, useLocation} from 'react-router-dom'
import '../../stiles/Header.css'
import logo from '../../icons/logo_dark.png'
import search from '../../icons/search.png'
import telegram from '../../icons/telegram.png'
import zen from '../../icons/zen.png'
import admin from '../../icons/admin.png'
import exit from '../../icons/exit.png'
import menu from '../../icons/menu.png'
import bag from '../../icons/bag.png'
import {  Avatar, Badge, Space, Drawer } from 'antd';
import Bag from '../Bag';

const Menu = observer(({open, onClose}) => {
    const {user} = useContext(Context)

    const logOut = () => {
        user.setUser({})
        user.setIsAuth(false)
        localStorage.removeItem('token')
      
    }
    

    return (
        <Drawer title="МАРБЛ ХАУС" 
        placement="left"
        onClick={() => onClose()}
        width={270} 
        closable={false} 
        onClose={onClose} 
        open={open}>

       <Nav className='mobile_navbar'>
                <NavLink  to={GAMEPLAY_ROUTE} style={{fontSize: '1.2rem'}}>Игровой процесс</NavLink>
                <NavLink  to={SHOP_ROUTE} style={{fontSize: '1.2rem'}}>Магазин</NavLink>
                <NavLink  to={BLOG_ROUTE} style={{fontSize: '1.2rem'}}>Блог</NavLink>
        </Nav> 

        <hr/>
        <nav className='mobile_contacts'>
            <NavLink  to={SEARCH_ROUTE}><img src={search} style={{maxWidth: '30px'}}/></NavLink>
        </nav>

        <hr/>
        <nav className='mobile_contacts'>
            <a href='https://t.me/cludintelligence'><img src={telegram}/></a>
            <a href='https://dzen.ru/id/65cb31dd9472bd5004721781'><img src={zen}/></a>
        </nav>

        
        
        {user.isAuth ?
                    <>
                    <hr/>
                    <Nav className='mobile_contacts'>
                        <NavLink to={ADMIN_ROUTE}>
                            <img src={admin}/>
                        </NavLink>

                        <NavLink  
                        onClick={() => logOut()}  
                        ><img src={exit}/>
                        </NavLink>
                    </Nav>
                    </>
                    :
                    <></>
                }
        
      </Drawer>
    );
});

export default Menu;