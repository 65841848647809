import {$authHost, $host} from "./index";


export const createSubscriber = async (email) => {
    try {
        const {data} = await $host.post('api/subscribers', email)
        return (data)
    } catch (e) {
        return (e.response.data)
    }
    
}

