import {$authHost, $host} from "./index";


export const createPost = async (post) => {
    try {
        const {data} = await $authHost.post('api/posts', post)
        return data
    } catch (error) {
        alert(error.response.data.message)
    }
}

export const fetchPosts = async (page, limit) => {
    try {
        const {data} = await $host.get('api/posts', {params: {
            page, limit
        }})
        return data
    } catch (error) {
        
    }
}

export const fetchRandomPosts = async (page, limit, except) => {
    try {
        const {data} = await $host.get('api/posts', {params: {
            page, limit, except
        }})
        return data
    } catch (error) {
        
    }
}

export const fetchOnePost = async (id) => {
    try {
        const {data} = await $host.get('api/posts/' + id)
        return data
    } catch (error) {
        
    }
}

export const deletePost = async (id) => {
    try {
        const {data} = await $authHost.delete('api/posts/' + id)
        return data
    } catch (error) {
        alert(error.response.data.message)
    }
}
