import {$authHost, $host} from "./index";


export const createMail = async (mail) => {
    try {
        const {data} = await $authHost.post('api/mail', mail)
        return data
    } catch (error) {
        alert(error.response.data.message)
    }
    
}


