import {makeAutoObservable} from "mobx";

export default class BagStore {
    constructor() {
        this._cartCount = 0
        makeAutoObservable(this)
    }


    setCartCount(count) {
        this._cartCount = count
    }

    get cartCount() {
        return this._cartCount
    }

}