import {$authHost, $host} from "./index";
import {jwtDecode as jwt_decode} from "jwt-decode";

export const createType = async (type) => {
    try {
        const {data} = await $authHost.post('api/type', type)
        return data
    } catch (error) {
        alert(error.response.data.message)
    }
    
}

export const fetchTypes = async () => {
    try {
        const {data} = await $host.get('api/type')
        return data
    } catch (error) {
        
    }
}

export const deleteType = async (id) => {
    try {
        const {data} = await $authHost.delete('api/type/' + id)
        return data
    } catch (error) {
        alert(error.response.data.message)
    }
    
}


export const createDevice = async (device) => {
    try {
        const {data} = await $authHost.post('api/games', device)
        return data
    } catch (error) {
        alert(error.response.data.message)
    }

}

export const fetchDevices = async (typeId, page, limit, order) => {
    try {
        const {data} = await $host.get('api/games', {params: {
            typeId, page, limit, order
        }})
        return data
    } catch (error) {
        
    }
    
}

export const fetchOneDevice = async (id) => {
    try {
        const {data} = await $host.get('api/games/' + id)
        return data
    } catch (error) {
        
    } 
}

export const deleteGame = async (id) => {
    try {
        const {data} = await $authHost.delete('api/games/' + id)
        return data
    } catch (error) {
        alert(error.response.data.message)
    }
    
}

export const createBannerItem = async (device) => {
    try {
        const {data} = await $authHost.post('api/banneritems', device)
        return data
    } catch (error) {
        alert(error.response.data.message)
    }
    
}

export const fetchBannerItem = async () => {
    try {
        const {data} = await $host.get('api/banneritems')
        return data
    } catch (error) {
        
    }  
}

export const deleteBannerItem = async (id) => {
    try {
        const {data} = await $authHost.delete('api/banneritems/' + id)
        return data
    } catch (error) {
        alert(error.response.data.message)
    }
    
}