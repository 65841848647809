import React, {useContext, useState} from 'react';
import {Context} from "../index";
// import Navbar from "react-bootstrap/Navbar";
// import Nav from "react-bootstrap/Nav";
import { Container, FormControl, Navbar, Nav, Form, Button } from 'react-bootstrap';
import {NavLink} from "react-router-dom";
import {ADMIN_ROUTE, LOGIN_ROUTE, SHOP_ROUTE, HOME_ROUTE, GAMEPLAY_ROUTE, BLOG_ROUTE, SEARCH_ROUTE, BAG_ROUTE} from "../utils/consts";
// import {Button, Form, FormControl} from "react-bootstrap";
import {observer} from "mobx-react-lite";
// import Container from "react-bootstrap/Container";
import {useNavigate, useLocation} from 'react-router-dom'
import '../stiles/Header.css'
import logo from '../icons/logo_dark.png'
import search from '../icons/search.png'
import telegram from '../icons/telegram.png'
import zen from '../icons/zen.png'
import admin from '../icons/admin.png'
import exit from '../icons/exit.png'
import menu from '../icons/menu.png'
import bag from '../icons/bag.png'
import {  Avatar, Badge, Space, Drawer } from 'antd';
import Bag from './Bag';
import Menu from './modals/Menu';




const NavBar = observer(() => {
    const {user} = useContext(Context)
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);

    const logOut = () => {
        user.setUser({})
        user.setIsAuth(false)
        localStorage.removeItem('token')
      
    }

    
      const onClose = () => {
        setOpen(false);
      };

    const burgerMenu = () => {
        setOpen(true);
    }

    const location = useLocation()
  
    if(location.pathname !== '/') {
    return (
        <>
        <header className='header'>
        <section className='header_container'>
        
        <Nav className='navbar'>
             <NavLink  to={GAMEPLAY_ROUTE}>Игровой процесс</NavLink>
            <NavLink  to={SHOP_ROUTE}>Магазин</NavLink>
          <NavLink  to={BLOG_ROUTE}>Блог</NavLink>
         </Nav>

        <NavLink className='menu_btn'
                            onClick={() => burgerMenu()} 
                             
                        >
                            <img src={menu} style={{maxWidth: '30px'}}/>
                        </NavLink>

        <nav className='mobile_navbar_logo'>
            <NavLink  to={HOME_ROUTE}><img src={logo} style={{maxWidth: '150px'}}/></NavLink>
        </nav>

        <nav className='mobil_icons'>
            {/* <NavLink  to={SEARCH_ROUTE}><img src={search} style={{maxWidth: '30px'}}/></NavLink> */}
            <Bag/>
        </nav>

        <nav className='contacts'>
            <a href='https://t.me/cludintelligence'><img src={telegram}/></a>
            <a href='https://dzen.ru/id/65cb31dd9472bd5004721781'><img src={zen}/></a>
        </nav>

        

        
        </section>
        <hr/>
        <section className='header_container_bottom'>
        <nav className='navbar'>
            <NavLink  to={SEARCH_ROUTE}><img src={search}/></NavLink>
        </nav>

        <nav className='navbar_logo'>
            <NavLink  to={HOME_ROUTE}><img src={logo}/></NavLink>
        </nav>


        {user.isAuth ?
                    <Nav className='navbar'>
                        <NavLink to={ADMIN_ROUTE}>
                            <img src={admin}/>
                        </NavLink>

                        <NavLink  
                        onClick={() => logOut()}  
                        ><img src={exit}/>
                        </NavLink>
 
                         <Bag/>
                        
                    </Nav>
                    :
                    <Nav className='navbar'>
                        <Bag/>
                    </Nav>
                }

     
        </section>
        <hr className='header_container_bottom'/>

        </header>    
        <Menu open={open} onClose={() => setOpen(false)}/>
      </>
    )};
});

export default NavBar;