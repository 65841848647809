import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {Row} from "react-bootstrap";
import DeviceItem from "./DeviceItem";
// import '../stiles/GameList.css'

const DeviceList = observer(() => {
    const {device} = useContext(Context)

    return (
        <>
            {device.devices.map(device =>
                <DeviceItem key={device.id} device={device}/>
            )}
        </>

        
    );
});

export default DeviceList;