import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import ListGroup from "react-bootstrap/ListGroup";
import batch from '../icons/batch_opacity.png'
import '../stiles/Loader.css'

const Loader = observer(() => {
    
    return (
        <>
        <main className='loaderContainer'>
            <div className='loaderText'>
            <div className="variable">
                <span className="load">LOADI</span>NG...
            </div>
            <img className="loaderBatch" src={batch}/>
            </div>
        </main>
        </>
    );
});

export default Loader;