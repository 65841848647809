import React, {createContext, StrictMode} from 'react';
import ReactDOM from 'react-dom/client';


import { createRoot } from "react-dom/client";
import App from './App';
import UserStore from "./store/UserStore";
import DeviceStore from "./store/DeviceStore";
import BlogStore from "./store/BlogStore";
import StagesStore from "./store/StagesStore";
import AboutStore from "./store/AboutStore";
import BagStore from './store/BagStore';


export const Context = createContext(null)

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <Context.Provider value={{
        user: new UserStore(),
        device: new DeviceStore(),
        post: new BlogStore(),
        stage: new StagesStore(),
        about: new AboutStore(),
        cartStore: new BagStore(),
    }}>
        <App />
    </Context.Provider>,
    </StrictMode>
);