import React, {useContext, Component} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import ListGroup from "react-bootstrap/ListGroup";
import {makeAutoObservable} from "mobx";
import Dropdown from 'react-bootstrap/Dropdown';
import { Select, Space, ConfigProvider } from 'antd';
import '../stiles/SortBar.css'

const SortBar = observer(() => {
  const {device} = useContext(Context)

  const handleChange = (value) => {
    device.setSelectedOrder(value)
  };
  return (
    <div className='sortBar'>
<ConfigProvider
                theme={{
                    components: {
                       
                      Select: {
                            borderRadius: 0,
                            colorPrimary: 'grey',
                            textHoverBg: 'none',
                            activeBorderColor: 'white',
                            algorithm: true, // Enable algorithm
                    }
                    },
                }}
                >
    <Space wrap>
    <Select
      defaultValue='id,ASC'
      size = 'large'
      style={{width: 300, textAlign: 'left'}}
      onChange={handleChange}
      options={[ 
        { value: 'id,ASC', label: 'По дате, от новых к старым' },
        { value: 'id,DESC', label: 'По дате, от старых к новым' },
        { value: 'price,ASC', label: 'По цене, по возрастанию' },
        { value: 'price,DESC', label: 'По цене, по убыванию' },
        { value: 'level,ASC', label: 'По сложности, по возрастанию' },
        { value: 'level,DESC', label: 'По сложности, по убыванию' },
      ]}
    />
  </Space>
  </ConfigProvider>
  </div>
  );
});

export default SortBar;
