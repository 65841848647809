import React, {useContext} from 'react';
import {Context} from "../index";
// import Navbar from "react-bootstrap/Navbar";
// import Nav from "react-bootstrap/Nav";
import { Container, FormControl, Navbar, Nav, Form, Button } from 'react-bootstrap';
import {NavLink} from "react-router-dom";
import {ADMIN_ROUTE, LOGIN_ROUTE, SHOP_ROUTE, HOME_ROUTE, GAMEPLAY_ROUTE, BLOG_ROUTE} from "../utils/consts";
// import {Button, Form, FormControl} from "react-bootstrap";
import {observer} from "mobx-react-lite";
// import Container from "react-bootstrap/Container";
import {useNavigate} from 'react-router-dom'
import '../stiles/ByNow.css'
import logo from '../icons/logo.png'
import search from '../icons/search.png'
import instagram from '../icons/instagram.png'
import admin from '../icons/admin.png'
import exit from '../icons/exit.png'
import menu from '../icons/menu.png'



const ByNow = observer(() => {
    const navigate = useNavigate()

    return (
<section  className='ByNowContainer'>
        <article className='ByNow'>
                <h2>КУПИТЕ ИГРУ МАРБЛ ХАУС ПРЯМО СЕЙЧАС</h2>
                        <Button
                            variant={"outline-dark"}
                            onClick={() => navigate(SHOP_ROUTE)}
                            className='ByNowButton'
                        >КУПИТЬ СЕЙЧАС</Button>
        </article>
</section>

    );
});

export default ByNow;