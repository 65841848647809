import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import ListGroup from "react-bootstrap/ListGroup";
import { fetchDescriptions } from "../http/aboutAPI";
import Pages from "../components/Pages";
import SortBar from '../components/SortBar';
import Image from "react-bootstrap/Image"
import '../stiles/HomeAbout.css'
import batch from '../icons/batch.png'
import {  Avatar, Badge, Space, Drawer } from 'antd';
import {NavLink} from "react-router-dom";
import bag from '../icons/bag_light.png'
import { BAG_ROUTE} from "../utils/consts";


const BagLight = observer(() => {
    const {cartStore} = useContext(Context)

    // let count = 0;
    // let cart = JSON.parse(localStorage.getItem("bag"));
    // if (cart) count = cart.length;

    // var updCount = () => {
    //     let cart = JSON.parse(localStorage.getItem("bag"));
    //     count = cart.length;
    // }

  
    return (
        <>
        <NavLink to={BAG_ROUTE}>
                        <Badge count={cartStore.cartCount}>
                            <img src={bag} style={{maxWidth: '30px'}}/>
                            </Badge>
                        </NavLink>

        </>
    );
     
});

export default BagLight;


