import React, {useContext, useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {Form, Button,  Dropdown} from "react-bootstrap";
import {deletePost, fetchPosts} from "../../http/blogAPI";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

const DeletePost = observer(({show, onHide}) => {
    const [value, setValue] = useState('')
    const {post} = useContext(Context)

    const delPost = () => {
        deletePost(post.selectedPost.id)
        .then(data => {if (data) {alert(data.message);
            window.location.reload()}}) 
    };


    useEffect(() => {
        fetchPosts(null, 0, 10000).then(data => {
            post.setPosts(data.rows)
        })
    }, [])

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Удалить новость
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Dropdown className="mt-2 mb-2">
                        <Dropdown.Toggle>{post.selectedPost.name || "Выберите новость"}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {post.posts.map(item =>
                                <Dropdown.Item
                                    onClick={() => 
                                        post.setSelectedPost(item) 
                                    }
                                    key={item.id}
                                    
                                >
                                    {item.name}
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-danger" onClick={onHide}>Закрыть</Button>
                <Button variant="outline-success" onClick={delPost}>Удалить</Button>
            </Modal.Footer>
        </Modal>
    );
});

export default DeletePost;