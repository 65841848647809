import React, {useContext, useEffect} from 'react';
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TypeBar from "../components/TypeBar";
import DeviceList from "../components/DeviceList";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import { fetchDevices, fetchTypes} from "../http/deviceAPI";
import Pages from "../components/Pages";
import GameplayRounds from '../components/GameplayRounds';
import Footer from '../components/Footer';
import GameplayAbout from '../components/GameplayAbout';
import ByNow from '../components/ByNow';

const Gameplay = observer(() => {
   

    return (
        <>
        <GameplayAbout/>
        <ByNow />
        <GameplayRounds/>
        </>
    );
});

export default Gameplay;