import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {Pagination} from "react-bootstrap";
import '../stiles/Pagination.css'

const PostsPages = observer(() => {
    const {post} = useContext(Context)
    const pageCount = Math.ceil(post.totalCount / post.limit)
    const pages = []

    for (let i = 0; i < pageCount; i++) {
        pages.push(i + 1)
    }

    return (
        <Pagination className="mt-4">
            {pages.map(page =>
                <Pagination.Item
                    key={page}
                    active={post.page === page}
                    activeLabel=''
                    onClick={() => post.setPage(page)}
                >
                    {page}
                </Pagination.Item>
            )}
        </Pagination>
    );
});

export default PostsPages;