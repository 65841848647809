import React, {useContext, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import '../stiles/SubscriberBar.css'
import {startSearch} from "../http/searchAPI";
 
const SearchBar = observer(({childToParent}) => {
  const [value, setValue] = useState('')
  const [response, setResponse] = useState('')

  const Search = () => {
    startSearch(value)
    .then(data => {
      if (data == 400) {
        setResponse("Ничего не найдено. Попробуйте поискать что-нибудь другое.");
        return
      }
      if (data == 500) {
        setResponse("Ошибка сервера. Попробуйте поискать что-нибудь позже");
        return
      }
      if (data) {
      setResponse('');
      childToParent(JSON.stringify(data))}
    })
    .catch((e) => {
      setResponse("Ошибка сервера. Попробуйте поискать что-нибудь позже");
      return
       })
}
    
    return (
        <section className='subscriber'>
        <h2>ПОИСК</h2>
        <p>{response}</p>
        <InputGroup className="form">
        <Form.Control
          value={value}
          onChange={e => setValue(e.target.value)}
          placeholder="Введите Ваш запрос"
        />
        <Button variant="dark" onClick={Search} style={{borderRadius: '0px'}}>
          НАЙТИ
        </Button>
      </InputGroup>

      </section>
    );
});

export default SearchBar;