import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {useParams} from 'react-router-dom'
import {fetchOnePost, fetchRandomPosts} from "../http/blogAPI";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import '../stiles/Post.css'
import {useNavigate} from 'react-router-dom'
import {ADMIN_ROUTE, LOGIN_ROUTE, SHOP_ROUTE, HOME_ROUTE, GAMEPLAY_ROUTE, BLOG_ROUTE} from "../utils/consts";
import { ArrowLeftOutlined } from '@ant-design/icons';



import { Image, Space } from 'antd';
import RandomPostsList from '../components/RandomPostsList';
import Loader from '../components/Loader';

const PostPage = observer(() => {
    const [postItem, setPost] = useState('')
    const {post} = useContext(Context)
    const {id} = useParams()
    const navigate = useNavigate()
    let postText = []
    const [loading, setLoading] = useState(true)
    let loaded = false;

    useEffect(() => {
        fetchRandomPosts(1, 3, id).then(data => {
            if (data) {
            post.setPosts(data.rows)
            post.setTotalCount(data.count)}
        })

        fetchOnePost(id).then(data => {
            if (data) {
            setPost(data)
            loaded = true;
            }
        })
        .catch((e) => {
            loaded = false;
             })
        .finally(() => {if (loaded) setLoading(false)})
    }, [])



    const formatter = new Intl.DateTimeFormat("ru", {
        year: "numeric",
        month: "long",
        day: "2-digit"
      });

    if (postItem) {
        postText = postItem.text.split('<br>')    
    }


      if (loading) {
        return (<Loader/>); 
      }
    return (
        <><section className="postContainer">
            
                    
            <div className="data">{formatter.format(Date.parse(postItem.createdAt)).substring(0, formatter.format(Date.parse(postItem.createdAt)).length - 3)}</div>

            <h2>{postItem.name}</h2>

            <article>
                        <figure className="picture">
                        <Image
                        src={process.env.REACT_APP_API_URL + postItem.img}
                        />
                        </figure>        
                {postText.map(Span =>
                <p>{Span}</p>
            )}
            </article>     
        </section>
    
        <hr/>
        <section style={{textAlign: 'center'}}>
        <Button style={{margin: '0 auto'}}
                            variant={"dark"}
                            size="lg"
                            onClick={() => navigate(BLOG_ROUTE)}
                            className='ByNowButton'
                        ><ArrowLeftOutlined />   ВЕРНУТЬСЯ В БЛОГ</Button></section>
        <RandomPostsList/>

        </>
    );
});

export default PostPage;